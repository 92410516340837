// Options for the "filter by tag/block type" menu
.pgn__menu.filter-by-refinement-menu {
  .pgn__menu-item {
    // Make the "filter by tag/block type" menu expand to fit the tags hierarchy and longer block type names
    width: 100%;
  }
}

.clear-filter-button:hover {
  color: $info-900 !important;
}

.problem-menu-item {
  .pgn__menu-item-text {
    width: 100%;
  }

  .pgn__form-checkbox > div:first-of-type {
    width: 100%;
  }

  .problem-sub-menu-item {
    position: absolute;
    left: 3.8rem;
    top: -3rem;
  }
}

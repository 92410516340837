.settingsCardTitleSection {
    padding-bottom: 0;
}

.halfSpacedMessage {
    padding-bottom: .5rem;
}

.spacedMessage {
    padding-bottom: 1.5rem;
}

.settingsWidget {
    margin-top: 40px;

    .pgn__form-text {
        font-size: small;
    }
}

.resetCard {
    .resetSettingsButtons {
        width: 100%;
    }
}

.dropdown-certificate {
  .dropdown-certificate-label {
    font: normal $font-weight-bold .75rem/1.25rem $font-family-base;
    color: $gray-500;
    margin-bottom: .5rem;
  }

  .dropdown-certificate-control {
    .dropdown-certificate-control-toggle {
      font-size: $input-font-size;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      background: $input-bg;
      border-color: $input-border-color;
      border-width: $input-border-width;
      box-shadow: $input-box-shadow;
      border-radius: $input-border-radius;
      color: $input-color;
      padding: $input-padding-y $input-padding-x;
      height: $input-height;
      justify-content: space-between;
      width: 100%;

      &:not(:disabled, .disabled):active,
      &:not(:disabled, .disabled).active,
      .show & {
        color: $input-color;
        border-color: $input-bg;
        background-color: $input-bg;
      }
    }
  }

  .pgn__form-control-description {
    font: normal $font-weight-normal .75rem/1.25rem $font-family-base;
    color: $gray-500;
    margin-top: .5rem;
  }
}

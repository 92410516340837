.video-thumbnail {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: auto;
  overflow: hidden;
}

.video-thumbnail .thumbnail-overlay {
  background: rgba(0 0 0 / .7);
  position: absolute;
  height: 99%;
  width: 100%;
  inset: 0;
  opacity: 0;
  -webkit-transition: all .4s ease-in-out 0s;
  -moz-transition: all .4s ease-in-out 0s;
  transition: all .4s ease-in-out 0s;
}

.status-badge {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-thumbnail:hover .thumbnail-overlay {
  opacity: 1;
}

.add-thumbnail {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
}

.video-thumbnail:hover .add-thumbnail {
  top: 50%;
  left: 50%;
  opacity: 1;
}

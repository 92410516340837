@import "./breadcrumbs/Breadcrumbs";
@import "./course-sequence/CourseSequence";
@import "./add-component/AddComponent";
@import "./sidebar/Sidebar";
@import "./header-title/HeaderTitle";
@import "./move-modal";
@import "./preview-changes";

.course-unit {
  min-width: 900px;
}

.course-unit__alert {
  margin-bottom: 1.75rem;
}

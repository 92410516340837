.img-settings-form-container {
  .img-settings-thumbnail-container {
    width: 282px;

    .img-settings-thumbnail {
      margin-left: 32px;
      max-height: 250px;
      max-width: 250px;
    }
  }

  hr {
    width: 1px;
  }

  .img-settings-form-controls {
    width: 375px;
    margin: 0 24px;

    .dimension-input {
      width: 145px;
      margin-right: 15px;
      display: inline-block;
    }

    .img-settings-control-label {
      font-size: 1rem;
    }

    .decorative-control-label label {
      font-size: .75rem;
    }
  }
}

.library-authoring-page {
  .header-actions {
    .normal-border {
      border: 1px solid;
    }

    .open-border {
      border: 2px solid;
      margin: -1px 0;
    }
  }
}

.library-authoring-sidebar {
  z-index: 1000; // same as header
  flex: 450px 0 0;
  position: sticky;
  top: 0;
  right: 0;
  height: 100vh;
  overflow-y: auto;
}

.dropdown-menu {
  z-index: 1001; // over the sidebar
}

// Reduce breadcrumb bottom margin
ol.list-inline {
  margin-bottom: 0;
}
